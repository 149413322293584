<template>
  <div>
    <div
      style="padding:15px 0 40px;border-bottom:1px solid #E6E6E6;color:#292929;margin-bottom:30px;"
    >
      确定删除 {{ dept.dept_name }}？
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('onClose')">取 消</el-button>
      <el-button type="primary" @click="del">确 定</el-button>
    </span>
  </div>
</template>

<script>
import { delDept } from "@/service/company";
export default {
  props: {
    dept: Object
  },
  methods: {
    del() {
      const { comp_id, dept_id } = this.dept;
      delDept(comp_id, dept_id)
        .then(u => {
          this.$message.success("删除成功!");
          this.$emit("onSuccess");
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    }
  }
};
</script>

<style scoped></style>
