<template>
  <div>
    <div
      class="flex flex-align-center"
      style="padding: 30px 0;border-bottom: 1px solid #E6E6E6;margin-bottom: 30px;"
    >
      <div>
        <label style="width: 150px;">部门名称：</label>
        <el-input v-model="dept.dept_name"></el-input>
      </div>
      <div style="margin-top: 20px;" v-if="!dept.isSub && !dept.isMod">
        <label style="width: 150px;">上级部门：</label>
        <el-select v-model="parentDept" placeholder="请选择">
          <el-option
            v-for="item in depts"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('onClose')">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </div>
</template>
<script>
import { saveDept } from "@/service/company";
import { fetchTree } from "@/util";
export default {
  props: {
    dept: Object,
    depts: Array
  },
  data() {
    return {
      parentDept: null
    };
  },
  created() {},
  methods: {
    // 保存部门
    save() {
      if (this.parentDept) {
        this.dept.dept_pid = this.parentDept;
      }
      saveDept(this.dept)
        .then(() => {
          this.$message.success("提交成功");
          this.$emit("onSuccess");
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    }
  }
};
</script>
<style></style>
<style scoped>
.flex {
  padding: 30px 0;
  text-align: center;
}
.flex > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-input {
  width: 240px;
}
.el-select {
  width: 240px;
}
label {
  text-align: right;
}
</style>
