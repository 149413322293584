<template>
  <el-form :model="ruleForm" :rules="rules" ref="checkForm" label-width="120px" class="form">
        <el-form-item label="原管理员手机号" style="margin-bottom:20px;" prop="mobile">
            <div style="text-align:left;">{{userItem.mobile}}</div>
        </el-form-item>
        <el-form-item label="验证码" prop="code" style="margin-bottom:20px;">
            <el-input v-model="ruleForm.code" placeholder="请输入短信验证码">
                <template slot="append">
                    <div v-if="invsec<=0" @click="getMobileCode" style="cursor: pointer;">获取验证码</div>
                    <div v-if="invsec>0">{{invsec}}秒后重试</div>
                </template>
            </el-input>
        </el-form-item>
        <el-form-item label="新管理员" prop="new_manager_id" style="margin-bottom:20px;">
            <el-select v-model="ruleForm.new_manager_id" style="width:100%" placeholder="请选择新管理员">
                <el-option v-for="item in users" :key="item.id" :label="item.name+'-'+item.mobile" :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
        <div class="remind-text">
            <span style="font-size:14px;color:#F56C6C;">*</span>
            注意：更换管理员后，原管理员不再拥有管理员权限，请确认后操作
        </div>
        <div style="width:200px;margin:30px auto 0;" class="flex flex-justify-between">
            <el-button @click="hide()" size="small" type="primary" plain>取消</el-button>
            <el-button @click="submitForm()" size="small" type="primary">提交</el-button>
        </div>
    </el-form>
</template>

<script>
import { getMembersByDept, changeManager, changeManagerCode } from "@/service/company";
export default {
  props: {
    userItem:Object
  },
  data() {
      return {
        rules: {
            code: [
                { required: true, message: '请输入短信验证码', trigger: 'blur' }
            ],
            new_manager_id: [
                { required: true, message: '请选择新管理员', trigger: 'blur' }
            ],
        },
        queryOpt: {
            pagesize: 9999,
            page: 1,
            comp_id: this.$store.state.loginUser.comp.id,
            dept_id: -1
        },
        users: [],
        ruleForm:{
            code:null,
            new_manager_id:null,
        },
        invsec: 0,
        timer: null,
      }
  },
  created() {
      console.log(this.userItem);
      this.refreshMembers();
  },
  methods: {
    hide() {
        this.$emit("onClose");
    },
    refreshMembers() {
      getMembersByDept(this.queryOpt)
        .then(u => {
          this.users = u.list;
          if (u.total) {
            this.total = u.total;
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    getMobileCode() {
        changeManagerCode().then(() => {
            this.invsec = 60;
            this.timer = setInterval(() => {
                if (this.invsec == 0) {
                    clearInterval(this.timer);
                    return;
                }
                this.invsec--;
            }, 1000);
        }).catch(err => {
            console.log(err);
            this.$message.error(err.message);
        })
    },
    submitForm() {
        console.log(this.ruleForm);
        this.$refs['checkForm'].validate((valid) => {
            if (valid) {
                changeManager(this.ruleForm).then(()=>{
                    this.hide();
                    this.$emit("onSuccess");
                }).catch(err=>{
                    this.$message.error(err.message);
                });
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    },
  }
};
</script>

<style scoped>
    .form {
        max-width: 380px;
        margin: 0 auto;
    }
    .remind-text {
        height: 36px;
        line-height: 36px;
        border: 1px dashed #DBE1F6;
        font-size: 12px;
        color: #B3B3B3;
        margin-top: 60px;
    }
</style>
