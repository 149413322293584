<template>
    <div style="text-align: center;">
        <el-cascader :options="depts" :show-all-levels="false"
            :props="{multiple:true,emitPath:false,value:'id',label:'name'}" v-model="selectedDeptIds">
        </el-cascader>
        <div style="text-align: center;padding: 40px 0 10px 0;">
            <el-button type="primary" @click="save">保存</el-button>
        </div>
    </div>
</template>
<script>
    import { getDeptsByCompId } from '@/service/company';
    import { setMembersDept } from '@/service/account';
    import { fetchTree } from '@/util'
    export default {
        props: {
            userIds: Array
        },
        data() {
            return {
                depts: [],
                selectedDeptIds: []
            }
        },
        async created() {
            let _depts = await getDeptsByCompId(this.$store.state.loginUser.comp.id);
            this.depts = fetchTree(Object.values(_depts), 0, null);
        },
        methods: {
            save() {
                setMembersDept(this.$store.state.loginUser.comp.id, this.userIds, this.selectedDeptIds).then(() => {
                    this.$emit('onSuccess');
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },

        },
    }
</script>
<style>

</style>
<style scoped>

</style>